<template>
  <div>
    <el-form
        ref="form"
        v-bind="$attrs"
        :model="model"
        :rules="rules"
        :size="size"
        v-on="$listeners"
    >
      <el-row>
        <el-col :span="span">
          <el-form-item v-for="(field, i) in baseFields" :key="i" v-bind="field">
            <el-radio-group
                v-if="field.type == 'radio-group'"
                v-model="model[field.prop]"
                v-bind="field"
                @change="$emit('change', $event, field)"
                @blur="onBlur(field)"
            >
              <el-radio-button
                  v-for="(option, j) in field.options"
                  :key="j"
                  :label="option.value"
              >
                {{ option.label }}
              </el-radio-button>
            </el-radio-group>
            <el-date-picker
                v-else-if="field.type == 'daterange'"
                v-bind="field"
                :value="[model[field.prop1], model[field.prop2]]"
                type="daterange"
                @input="onDatePickerInput($event, field)"
                @blur="onBlur(field)"
            />
            <el-date-picker
                v-else-if="field.type == 'week'"
                v-model="model[field.prop]"
                v-bind="field"
                type="week"
                @blur="onBlur(field)"
            >
            </el-date-picker>
            <el-date-picker
                v-else-if="field.type == 'date'"
                v-model="model[field.prop]"
                v-bind="field"
                @blur="onBlur(field)"
            />
            <el-time-picker
                v-else-if="field.type == 'timerange'"
                is-range
                v-bind="field"
                :value="[model[field.prop1], model[field.prop2]]"
                @input="onTimePickerInput($event, field)"
                @blur="onBlur(field)"
            />
            <el-time-select
                v-else-if="field.type == 'time'"
                v-model="model[field.prop]"
                v-bind="field"
                @blur="onBlur(field)"
            />
            <el-switch
                v-else-if="field.type == 'switch'"
                v-model="model[field.prop]"
                v-bind="field"
                @blur="onBlur(field)"
            />
            <el-select
                v-else-if="field.type == 'select'"
                v-model="model[field.prop]"
                v-bind="field"
                @change="onSelectChange($event, field)"
                @blur="onBlur(field)"
            >
              <el-option
                  v-for="(option, j) in field.options"
                  v-bind="option"
                  :key="j"
              />
            </el-select>
            <el-cascader
                v-else-if="field.type == 'cascader'"
                v-model="model[field.prop]"
                v-bind="field"
                @blur="onBlur(field)"
            />
            <el-input-number
                v-else-if="field.type == 'input-number'"
                v-model="model[field.prop]"
                v-bind="field"
                :size="size"
                @blur="onBlur(field)"
            />
            <el-input
                v-else
                v-model="model[field.prop]"
                v-bind="field"
                @blur="onBlur(field)"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="uploadField" :span="12">
          <el-form-item v-bind="uploadField" label-width="auto">
            <el-upload
                v-bind="uploadField"
                :before-upload="beforeUpload"
                :http-request="httpRequest"
                @blur="onBlur(field)"
            >
              <template v-if="!model.picPath">
                <i class="el-icon-upload" />
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </template>
              <template v-else>
                <el-image :src="model.isUpdate ? model.baseUrl + model.picPath : imgUrlPath" fit="cover" />
              </template>
              <template #tip>
                <div class="el-upload__tip">
                  只能上传jpg/png文件
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="offset">
          <el-form-item v-if="submit">
            <el-button
                type="primary"
                :size="size"
                :loading="loading"
                icon="el-icon-check"
                @click="onConfirm"
            >
              提交
            </el-button>
            <el-button :size="size" icon="el-icon-close" @click="onCancel">
              取消
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog :close-on-click-modal="false" title="图片裁剪预览" append-to-body :visible.sync="showImageCropper" width="1000px">
      <vueCropper
          ref="cropper"
          style="height: 500px"
          :can-move="cropOption.canMove"
          :can-move-box="cropOption.canMoveBox"
          :img="cropOption.image"
          :fixed="cropOption.fixed"
          :infoTrue="cropOption.infoTrue"
          :height="cropOption.height"
          :high="cropOption.high"
          :centerBox="cropOption.centerBox"
          :outputSize="cropOption.size"
          :outputType="cropOption.type"
          :autoCrop="cropOption.autoCrop"
          :autoCropWidth="cropOption.autoCropWidth"
          :autoCropHeight="cropOption.autoCropHeight"
          :original="cropOption.original"></vueCropper>
      <span slot="footer" class="dialog-footer">
        点击确定上传图片
        <el-button type="primary" @click="imageCropper">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import request from '@/utils/request';
import get from 'lodash/get';
import { Message } from 'element-ui';
export default {
  name: 'AppForm',
  inheritAttrs: false,
  props: {
    // 表单提示的url
    url: String,
    // 表单的模型
    model: Object,
    /**
     * 表单的模型字段定义
     * label
     * prop
     * type
     * clearable
     * options
     * mapper
     * url
     */
    fields: Array,
    // 校验
    rules: Object,
    // 是否显示提交按钮
    submit: Boolean,
    // 界面尺寸
    size: String,
    // 自定义提交参数回调
    custom: Function
  },
  data() {
    return {
      loading: false,
      cropOption: {
        image: '',
        size: 0.8,
        type: 'jpeg',
        height: true,
        high: true,
        fixed: true,
        autoCrop: true,
        autoCropWidth: 300,
        autoCropHeight: 300,
        original: false,
        infoTrue: true,
        // 上传图片是否可以移动
        canMove: true,
        // 截图框能否拖动
        canMoveBox: true,
        // 固定截图框大小 不允许改变
        fixedBox: false,
        // 截图框是否被限制在图片里面
        centerBox: true,
      },
      showImageCropper:false,
      imgUrlPath:'',
    };
  },
  computed: {
    // 找出所有要填充下拉选项的字段
    optional() {
      return this.fields.filter(item => item.url);
    },
    // 除上传外的字段
    baseFields() {
      return this.fields.filter(item => item.type != 'upload');
    },
    // 上传字段
    uploadField() {
      return this.fields.find(item => item.type == 'upload');
    },
    // 列布局
    span() {
      return this.uploadField ? 12 : 24;
    },
    // 按钮行的列布局偏移量
    offset() {
      return this.uploadField ? 12 : 0;
    }
  },
  async created() {
    const { getOptions } = this;
    await getOptions();
  },
  methods: {
    // 填充下拉框中的选项
    async getOptions() {
      const { optional } = this;
      // 批量请求
      const requests = [];
      optional.forEach(option => {
        const req = request(option.url, {
          method: option.method,
          params: option.params,
          data: option.data
        });
        requests.push(req);
      });
      // 开始所有请求，等待完成
      const results = await Promise.all(requests);
      // 填充选项
      optional.forEach((field, index) => {
        let options;
        // 请求返回对象选择的键值
        if (field.returnKey) {
          options = get(results[index].data, field.returnKey);
        } else {
          options = results[index].data;
        }
        // 返回字段映射
        if (field.mapper) {
          field.options = options.map(field.mapper);
        } else {
          field.options = options;
        }
      });
    },
    // 确认操作
    async onConfirm() {
      const { $refs, url, model, custom } = this;
      try {
        this.loading = true;
        // 表单校验
        await $refs.form.validate();
        let data;
        // 自定义参数
        if (custom) {
          data = custom(model);
        } else {
          data = model;
        }
        await request({ url, method: 'post', data });
        this.$emit('confirm');
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // 取消操作
    onCancel() {
      this.$emit('cancel');
    },
    // 日期范围输入
    onDatePickerInput(e, field) {
      if (e) {
        this.model[field.prop1] = e[0];
        this.model[field.prop2] = e[1];
      } else {
        this.model[field.prop1] = '';
        this.model[field.prop2] = '';
      }
    },
    // 时间范围输入
    onTimePickerInput(e, field) {
      if (e) {
        this.model[field.prop1] = e[0];
        this.model[field.prop2] = e[1];
      } else {
        this.model[field.prop1] = '';
        this.model[field.prop2] = '';
      }
    },
    // 选择框变更时，变更其他字段
    onSelectChange(e, field) {
      const { model, fields, rules } = this;
      const { change } = field;
      if (change) {
        change(e, { field, fields, model, rules });
      }
    },
    //覆盖默认上传，走裁剪的上传文件，不走组件自带
    httpRequest() {
      return false;
    },
    //上传图片
    imageCropper() {
      this.$refs.cropper.getCropBlob(data => {
        const file = new File([data], 'image.jpg', {
          type: data.type,
          lastModified: Date.now(),
        });
        //格式化图片
        const formData = new FormData();
        //添加属性
        formData.append('file', file, file.name);
        const loading = this.$loading({
          lock: true,
          text: '图片上传中, 请稍后...',
          spinner: 'el-icon-loading',
        });
        //请求接口
        request.post(this.uploadField.action, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(res => {
              loading.close();
              console.log(res)
              if (res.code === 200) {
                console.log(res.data)
                this.model.picPath = res.data.savePath;
                this.imgUrlPath = res.data.urlPath;
                this.showImageCropper = false;
              }
            })
            .catch(e => {
              loading.close();
              this.$message.error(`图片上传失败！${JSON.stringify(e)}`);
            })
            .finally(() => {
              loading.close();
            });
      });
    },

    // 图片上传前校验
    beforeUpload(file) {
      const isJPG = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
      if (!isJPG) {
        this.$message.warning('外部图标只能是 jpg / png 格式');
        return false;
      }
      // const isLt500k = file.size / 1024 / 1024 < 0.5;
      // if (!isLt500k) {
      //   this.$message.warning('外部图标大小不能超过 500k');
      //   return false;
      // }
      this.cropOption.image = window.URL.createObjectURL(file);
      // 打开裁剪框
      this.showImageCropper = true;
    },


    // 上传前限制大小
    onBeforeUpload(file) {
      var more = file.size > 1024 * 100;
      if (more) {
        Message.error('图片大小不过超过100K');
        return false;
      }
      return true;
    },
    // 上传成功回调
    // onSuccess(response) {
    //   console.log('onSuccess')
    //   const { data } = response;
    //   if (data) {
    //     const { savePath } = data;
    //     this.model.picPath = savePath;
    //   }
    // },
    // 失去焦点
    onBlur(field) {
      this.$emit('blur', field);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-input,
  .el-input-number,
  .el-date-editor,
  .el-textarea,
  .el-radio-group {
    width: 250px;

    .el-input__inner {
      width: inherit;
    }
  }

  .el-upload,
  .el-upload-dragger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 280px;
  }
}

.el-image {
  width: 360px;
  height: 278px;
}
</style>
