import { render, staticRenderFns } from "./AppForm.vue?vue&type=template&id=25fd7eb9&scoped=true"
import script from "./AppForm.vue?vue&type=script&lang=js"
export * from "./AppForm.vue?vue&type=script&lang=js"
import style0 from "./AppForm.vue?vue&type=style&index=0&id=25fd7eb9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fd7eb9",
  null
  
)

export default component.exports